import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function getDirection(value = 'bottom') {
  return {
    top: 'to top',
    right: 'to right',
    bottom: 'to bottom',
    left: 'to left',
  }[value];
}

// ----------------------------------------------------------------------

const cssStyles = (theme) => ({
  /**
   * @param props {{
   *   blur: number,
   *   color: string,
   *   opacity: number,
   * }}
   * @return {{
   *   backdropFilter: string,
   *   backgroundColor: string,
   *   WebkitBackdropFilter: string,
   * }}
   */
  bgBlur: (props) => {
    const color = props?.color || theme?.palette.background.default || '#000000';

    const blur = props?.blur || 6;
    const opacity = props?.opacity || 0.8;

    return {
      backdropFilter: `blur(${blur}px)`,
      backgroundColor: alpha(color, opacity),
      WebkitBackdropFilter: `blur(${blur}px)`, // Fix on Mobile
    };
  },

  /**
   * @param props {{
   *   endColor: string,
   *   startColor: string,
   *   direction: ["top", "right", "bottom", "left"],
   * }}
   * @return {{
   *   background: string
   * }}
   */
  bgGradient: (props) => {
    const direction = getDirection(props?.direction);
    const endColor = props?.endColor || '#000000 75%';
    const startColor = props?.startColor || `${alpha('#000000', 0)} 0%`;

    return {
      background: `linear-gradient(${direction}, ${startColor}, ${endColor});`,
    };
  },

  /**
   * @param props {{
   *   url: string,
   *   direction: ["top", "right", "bottom", "left"],
   *   startColor: string,
   *   endColor: string,
   * }}
   * @return {{
   *   background: string,
   *   backgroundSize: string,
   *   backgroundRepeat: string,
   *   backgroundPosition: string,
   * }}
   */
  bgImage: (props) => {
    const url = props?.url;
    const direction = getDirection(props?.direction);
    const endColor = props?.endColor || alpha(theme?.palette.grey[900] || '#000000', 0.88);
    const startColor = props?.startColor || alpha(theme?.palette.grey[900] || '#000000', 0.88);

    return {
      background: `linear-gradient(${direction}, ${startColor}, ${endColor}), url(${url})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
  },
});

export default cssStyles;
