import PropTypes from 'prop-types';

// @mui
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// hooks
import useResponsive from 'hooks/useResponsive';

const BUTTON_SIZE = 40;

const ArrowStyle = styled(IconButton)(() => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  width: BUTTON_SIZE,
  height: BUTTON_SIZE,
  transition: 'all 0.2s ease-in-out',

  '& .MuiSvgIcon-root': {
    fontSize: '50px',
  },
}));

const CarouselArrows = ({ onNext, onPrevious, children, ...other }) => {
  const isTablet = useResponsive('down', 'md');

  return (
    <Box {...other} position="relative">
      {!isTablet && (
        <Box
          sx={{
            position: 'absolute',
            left: '-2.2rem',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <ArrowStyle onClick={onPrevious}>
            <ChevronLeftIcon color="primary" />
          </ArrowStyle>
        </Box>
      )}

      {children}

      {!isTablet && (
        <Box
          sx={{
            position: 'absolute',
            right: '2rem',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <ArrowStyle onClick={onNext}>
            <ChevronRightIcon color="primary" />
          </ArrowStyle>
        </Box>
      )}
    </Box>
  );
};

CarouselArrows.propTypes = {
  children: PropTypes.node.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};

export default CarouselArrows;
